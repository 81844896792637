import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { Nav } from "react-bootstrap";
import profile_pic from "../images/IanMcCreadie-G.jpg";
import amazon_preview from "../images/amazon_preview.jpg";
import prox_preview from "../images/GeorgiaTech_Prox-1_2.jpg";

const About = () => {
  return (
    <>
      <Container className="mt-3">
        <Row>
          <Col md={7}>
            <Row>
              <Col className="fs-1" md={12}>
                <p style={{ textAlign: "left" }}>Ian McCreadie</p>
              </Col>
              <Col className="fs-6 mt-3" md={12}>
                <p style={{ textAlign: "left" }}>
                  I'm Ian McCreadie, a software engineer who enjoys solving hard
                  problems to develop, deploy, and scale complex systems.
                  Currently working at Amazon in the Robotics AI org where I
                  build simulation capabilities, data processing pipelines, and
                  visualizations for autonomous mobile robots.
                  <br />
                  <br />
                  Previously, I built data processing and performance monitoring
                  solutions for several complex systems in the space industry. I
                  worked primarily on programs developing RF and OPIR based
                  object tracking algorithms.
                  <br />
                  <br />
                  I have a BS in Aerospace Engineering and an MS in Computer
                  Science with a specialization in Computational Perception and
                  Robotics from Georgia Tech.
                  <br />
                  <br />
                  Use the email in my resume to contact me.
                </p>
              </Col>
            </Row>
            <Row></Row>
          </Col>
          <Col md={5}>
            <Image
              className="w-50 rounded-circle"
              src={profile_pic}
              alt="Profile Pic"
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h6 style={{ textAlign: "left" }}>Past Projects</h6>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col md={5}>
            <p style={{ textAlign: "left" }}>
              At Amazon I worked on the Canvas team developing autonomous mobile
              robots which were tested in several sites across the USA. I worked
              across the Simulation and Analytics teams to improve developer
              workflows and better understand system performance. My efforts
              enabled faster and actionable integration testing earlier in the
              software development lifecycle. My work simplifying access to high
              frequency data enabled faster issue triage and improved alarming
              capabilities across the organization. See if you can spot me in
              the video.{String.fromCodePoint(0x1f603)}
            </p>
          </Col>
          <Col md={7}>
            <Nav.Link
              className="card mb-3"
              href="https://www.amazon.science/latest-news/the-quest-to-deploy-autonomous-robots-within-amazon-fulfillment-centers"
            >
              <div className="row no-gutters">
                <div className="col-md-4">
                  <Image
                    className="card-img"
                    src={amazon_preview}
                    alt="amazon-preview"
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <h6 className="card-title">
                      The quest to deploy autonomous robots in fulfillment
                      centers
                    </h6>
                    <p className="card-text">
                      <small>
                        Company is testing a new class of robots which use
                        artificial intelligence and computer vision to move
                        freely throughout facilities.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </Nav.Link>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col md={5}>
            <p style={{ textAlign: "left" }}>
              During my time at Georgia Tech I led the Thermal Control Subsystem
              Team where we{" "}
              <Nav.Link
                className="link-primary"
                style={{ display: "inline" }}
                href="http://www.satnews.com/story.php?number=756757824"
              >
                won
              </Nav.Link>{" "}
              the University Nanosatellite Competition. The prize was a launch
              slot to orbit as a secondary payload. Our mission was to deploy
              the Planetary Society's{" "}
              <Nav.Link
                className="link-primary"
                style={{ display: "inline" }}
                href="https://www.planetary.org/planetary-report/tpr-2019-3"
              >
                Lightsail-2
              </Nav.Link>{" "}
              (Yes, I have met Bill Nye) , perform proximity operations around
              it, and then observe the solar sail deployment. After I graduated
              the team continued the work and it launched successfully in
              2019!!!
            </p>
          </Col>
          <Col md={7}>
            <Nav.Link
              className="card mb-3"
              href="https://ae.gatech.edu/news/2019/06/aes-prox-1-satellite-launches-kennedy-space-center"
            >
              <div className="row no-gutters">
                <div className="col-md-4">
                  <Image
                    className="card-img"
                    src={prox_preview}
                    alt="gatech-preview"
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <h6 className="card-title">
                      AE's Prox-1 Satellite Launches from Kennedy Space Center
                    </h6>
                    <p className="card-text">
                      <small>
                        Years of planning, design, and testing paid off
                        handsomely on June 25, as the Georgia Tech-built Prox-1
                        satellite was finally launched...
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </Nav.Link>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default About;
