import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { LinkContainer } from "react-router-bootstrap";
import resume_pdf from "../pdfs/McCreadie_Resume_2022_Oct.pdf";

const Navigation = () => {
  return (
    <>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <LinkContainer to="/">
                <Nav.Link>About</Nav.Link>
              </LinkContainer>
              <Nav.Link
                href={resume_pdf}
                rel="noopener noreferrer"
                target="_blank"
              >
                Resume
              </Nav.Link>
              {/* <LinkContainer to="/blog">
                <Nav.Link>Blog</Nav.Link>
              </LinkContainer> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
export default Navigation;
